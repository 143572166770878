import React, {useState, FormEvent, ChangeEvent} from 'react';

import ButtonV2 from '@/components/redesign/ButtonV2';
import {subscribeEmail} from '@/utils/subscribe/api';
import toaster from '@/utils/toaster';
import {useTracker} from '@/components/analytics/TrackerProvider';

const SUBSCRIBE_HEADING = 'Subscribe for updates';
const SUBSCRIBE_INPUT_PLACEHOLDER = 'Enter your email address';
const SUBSCRIBE_BUTTON_TEXT = 'Sign up';

const SubscribeForUpdates: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const tracker = useTracker();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await subscribeEmail(email, 'house_of_lee');
      toaster.success('Successfully subscribed!');
      tracker.capture('email-subscribe', {
        email,
        source: 'landing_page_v1',
      });
      setEmail('');
    } catch (err: any) {
      console.error(err);
      toaster.error(
        String(err?.message) ||
          "There's an issue with subscribing. Please try again."
      );
    }
  };

  return (
    <div className="flex w-full flex-col gap-3 sm:gap-[0.938rem] sm:pb-[1.313rem]">
      <h3 className="shib-small-text text-grayscale-300">
        {SUBSCRIBE_HEADING}
      </h3>
      <form className="flex w-full flex-row gap-3" onSubmit={handleSubmit}>
        <input
          className="shib-small-text w-full max-w-[37.5rem] rounded bg-grayscale-700 px-[1.563rem] py-5 text-grayscale-500"
          placeholder={SUBSCRIBE_INPUT_PLACEHOLDER}
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        />
        <ButtonV2 text={SUBSCRIBE_BUTTON_TEXT} />
      </form>
    </div>
  );
};

export default SubscribeForUpdates;
