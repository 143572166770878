import Link from 'next/link';
import React from 'react';

import {NavMenuV2} from '@/components/redesign/MenuV2';
import {NavBarProfileButton} from '@/components/auth/privy/NavBarLoginButton';
import {usePrivy} from '@privy-io/react-auth';

export type Page = {
  name: string;
  href: string;
  subpages: {
    name: string;
    href: string;
  }[];
};

export interface Config {
  showMetamask?: boolean;
  showShibuyaLogo?: boolean;
}

const ABOUT_PAGE: Page = {
  name: 'About',
  href: '/about',
  subpages: [],
};

const SHOWS_PAGES: Page = {
  name: 'Shows',
  href: '',
  subpages: [
    {
      name: 'White Rabbit',
      href: '/shows/white-rabbit',
    },
    {
      name: 'House of Lee',
      href: '/shows/house-of-lee',
    },
    {
      name: 'Dominion X',
      href: '/shows/dominionx',
    },
  ],
};

const DASHBOARD_PAGE: Page = {
  name: 'Dashboard',
  href: '/white-rabbit/dashboard',
  subpages: [],
};

const HOME_PAGES: Page[] = [ABOUT_PAGE, DASHBOARD_PAGE, SHOWS_PAGES];

const LOGGED_OUT_HOME_PAGES: Page[] = [ABOUT_PAGE, SHOWS_PAGES];

export const ShibuyaLogoOnly = ({
  className,
  widthClassNames = 'mx-auto',
}: {
  className?: string;
  widthClassNames?: string;
}) => {
  return (
    <nav className={className}>
      <div
        className={`flex min-h-[7.75rem] w-full items-center justify-between px-9 py-8 sm:px-16 sm:py-9 ${widthClassNames}`}
      >
        <div className="flex items-center">
          <Link href="/" legacyBehavior>
            <a>
              <img
                alt="Shibuya logo"
                src="/images/shibuya-logo-white.svg"
                className="h-6 sm:h-8"
              />
            </a>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export const NavBar = ({
  className = 'bg-black',
  widthClassNames = 'mx-auto',
  loggedInPages = HOME_PAGES,
  loggedOutPages = LOGGED_OUT_HOME_PAGES,
  config = {showMetamask: true, showShibuyaLogo: true},
}: {
  className?: string;
  widthClassNames?: string;
  loggedInPages?: Page[];
  loggedOutPages?: Page[];
  config?: Config;
}) => {
  const {user} = usePrivy();

  return (
    <nav className={className}>
      <div
        className={`flex min-h-[7.75rem] w-full items-center justify-between px-9 py-8 sm:px-16 sm:py-9 ${widthClassNames}`}
      >
        {config?.showShibuyaLogo && (
          <div className="flex items-center">
            <Link href="/" legacyBehavior>
              <a>
                <img
                  alt="Shibuya logo"
                  src="/images/shibuya-logo-white.svg"
                  className="h-6 sm:h-8"
                />
              </a>
            </Link>
          </div>
        )}
        <div className="flex items-center gap-2 sm:gap-0">
          <NavMenuV2 pages={user ? loggedInPages : loggedOutPages} />
          <NavBarProfileButton />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
