import React from 'react';
import Link from 'next/link';

import SubscribeForUpdates from '@/components/redesign/SubscribeForUpdates';
import ExternalLink from '@/components/ExternalLink';

const SUBSTACK_ARTICLES = [
  {
    title:
      "Shibuya Announces First External IP in Partnership with Steve Aoki and Seth Green's Stoopid Buddy Stoodios",
    href: 'https://shibuyaxyz.substack.com/p/shibuya-announces-first-external',
  },
  {
    title: 'Chapter 3 Release Ft. The Lava Game',
    href: 'https://shibuyaxyz.substack.com/p/chapter-3-release-ft-the-lava-game',
  },
  {
    title: 'Chapter 3 Mint Mechanics',
    href: 'https://shibuyaxyz.substack.com/p/chapter-3-mint-mechanics',
  },
  {
    title: 'Shibuya Raises $6.9M In Seed Funding',
    href: 'https://shibuyaxyz.substack.com/p/shibuya-raises-69m-in-seed-funding',
  },
];

const SOCIAL_LINKS = [
  {
    title: 'Twitter',
    href: 'https://twitter.com/shibuyafilm',
  },
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/shibuya.film/',
  },
  {
    title: 'Discord',
    href: 'https://discord.gg/shibuyafilm',
  },
  {
    title: 'Substack',
    href: 'https://shibuyaxyz.substack.com/',
  },
];

const LEARN_MORE_LINKS = [
  {
    title: 'Careers',
    href: 'https://shibuyaxyz.notion.site/Careers-Shibuya-d11af58847c047a38beaa0b0473da43b',
  },
  {
    title: 'Our Team',
    href: '/about',
  },
];

const BlogPosts = () => {
  return (
    <div className="flex flex-col gap-1 sm:gap-2">
      <h2 className="shib-small-text mb-1 text-grayscale-300">
        Read our blog posts
      </h2>
      {SUBSTACK_ARTICLES.map(({title, href}) => {
        const formattedTitle =
          title.length > 50 ? `${title.slice(0, 50)}...` : title;
        return (
          <ExternalLink
            className="shib-small-text text-grayscale-500 transition-colors hover:text-grayscale-300"
            href={href}
            key={href}
          >
            &quot;{formattedTitle}&quot;
          </ExternalLink>
        );
      })}
    </div>
  );
};

const SocialLinks = () => {
  return (
    <div className="flex flex-grow flex-col gap-1 sm:gap-2">
      <h2 className="shib-small-text mb-1 text-grayscale-300">Follow us</h2>
      {SOCIAL_LINKS.map(({title, href}) => (
        <ExternalLink
          className="shib-small-text text-grayscale-500 transition-colors hover:text-grayscale-300"
          href={href}
          key={href}
        >
          {title}
        </ExternalLink>
      ))}
    </div>
  );
};

const LearnMore = () => {
  return (
    <div className="flex flex-grow flex-col gap-1 sm:gap-2">
      <h2 className="shib-small-text mb-1 whitespace-nowrap text-grayscale-300">
        Learn more
      </h2>
      {LEARN_MORE_LINKS.map(({title, href}) => (
        <Link href={href} key={href} legacyBehavior>
          <a className="shib-small-text text-grayscale-500 transition-colors hover:text-grayscale-300">
            {title}
          </a>
        </Link>
      ))}
    </div>
  );
};

const CopyrightPrivacyTOS = () => {
  return (
    <p className="shib-small-text mr-2 flex-grow-[2] text-left text-grayscale-500">
      &copy; {new Date().getFullYear()} NotShibuya, Inc. &middot;{' '}
      <Link href="/privacy-policy" legacyBehavior>
        <a className="transition-colors hover:text-grayscale-300">Privacy</a>
      </Link>{' '}
      &middot;{' '}
      <Link href="/terms-of-service" legacyBehavior>
        <a className="transition-colors hover:text-grayscale-300">TOS</a>
      </Link>
    </p>
  );
};

export const SmallMediumScreensFooter = () => {
  return (
    <div className="flex flex-col gap-9 lg:hidden">
      <SubscribeForUpdates />
      <div className="flex w-full flex-col gap-9 md:flex-row md:justify-between md:gap-9">
        <BlogPosts />
        <div className="grid w-full grid-cols-2 gap-6 md:w-1/2">
          <SocialLinks />
          <LearnMore />
        </div>
      </div>

      <CopyrightPrivacyTOS />
    </div>
  );
};

export const LargeScreensFooter = () => {
  return (
    <div className="hidden space-x-16 lg:flex">
      <div className="flex w-full flex-col gap-0">
        <SubscribeForUpdates />
        <CopyrightPrivacyTOS />
      </div>
      <div className="flex w-full gap-9">
        <BlogPosts />
        <SocialLinks />
        <LearnMore />
      </div>
    </div>
  );
};

export const FooterV2 = ({className = ''}: {className?: string}) => {
  return (
    <footer
      className={`border-t border-grayscale-600 px-9 py-9 lg:px-16 lg:py-12 ${className}`}
    >
      <SmallMediumScreensFooter />
      <LargeScreensFooter />
    </footer>
  );
};

export default FooterV2;
