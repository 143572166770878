import Image, {ImageProps} from 'next/image';

interface DynamicImageProps extends ImageProps {
  alt: string;
  layout?: 'fill' | 'responsive' | 'intrinsic' | 'fixed';
}

/**
 * Wrapper around the next/image Image component with app defaults.
 * Use this one rather than the next/image Image component directly.
 */
export const DynamicImage: React.FC<DynamicImageProps> = ({
  alt,
  quality = 100,
  ...props
}) => {
  return (
    <Image
      style={{
        objectFit: 'cover',
      }}
      quality={quality}
      {...props}
      alt={alt}
    />
  );
};
