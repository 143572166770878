import React from 'react';
import Link from 'next/link';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import * as Popover from '@radix-ui/react-popover';

export type Page = {
  name: string;
  href: string;
  subpages: {
    name: string;
    href: string;
  }[];
};

export const NavMenuV2 = ({pages = []}: {pages: Page[]}) => {
  return (
    <div className="flex items-center">
      <MediumScreensMenu pages={pages} />
      <SmallScreensMenu pages={pages} />
    </div>
  );
};

const MediumScreensMenu = ({pages}: {pages: Page[]}) => {
  return (
    <div className="hidden md:flex">
      {pages.map((page) => (
        <MenuBarLinkV2 page={page} key={page.name} />
      ))}{' '}
    </div>
  );
};

const MenuBarLinkV2 = ({page}: {page: Page}) => {
  if (page.subpages && page.subpages.length > 0) {
    return (
      <Popover.Root>
        <Popover.Trigger>
          <button
            className="flex items-center rounded-md 
               py-2 px-9 text-base font-medium text-white
                hover:bg-opacity-90 hover:text-grayscale-300 sm:justify-center"
          >
            <span>{page.name}</span>
            <ChevronDownIcon className="ml-1 h-4 w-4" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="z-10" sideOffset={5}>
            <div className="relative flex w-full flex-col divide-y divide-gray-400 whitespace-nowrap rounded-md shadow">
              {page.subpages.map((subpage) => (
                <Link href={subpage.href} key={subpage.name} legacyBehavior>
                  <a
                    className="flex items-center bg-gray-600 bg-opacity-80 backdrop-blur-lg py-5 px-6 text-sm font-bold
                      text-gray-100 first:rounded-t-lg last:rounded-b-lg hover:bg-opacity-50 hover:text-gray-100"
                  >
                    {subpage.name}
                  </a>
                </Link>
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  } else {
    return (
      <Link href={page.href} legacyBehavior>
        <a
          className="shib-text-description flex items-center rounded-md bg-opacity-50 px-9
          py-2 font-medium text-white hover:bg-opacity-90 hover:text-grayscale-300 sm:justify-center"
        >
          {page.name}
        </a>
      </Link>
    );
  }
};

const SmallScreensMenu = ({pages}: {pages: Page[]}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Popover.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
      modal={true}
    >
      <Popover.Trigger className="inline-flex items-center justify-center border-none p-2 text-white hover:text-grayscale-300 md:hidden">
        <span className="sr-only">Open menu</span>
        <Bars3Icon
          title="Open menu"
          className={`${
            open ? 'hidden' : 'visible'
          } z-50 h-8 w-8 bg-transparent`}
          aria-hidden="true"
        />
      </Popover.Trigger>
      <Popover.Anchor className="absolute top-0 left-0" />
      <Popover.Portal>
        <Popover.Content
          className="z-10 flex h-screen
          flex-col items-start bg-gray-900 bg-opacity-90 p-8 md:hidden"
        >
          <Popover.Close
            className=" h-[25px] w-[25px] inline-flex items-center justify-center absolute top-[12px] right-[12px] cursor-default"
            aria-label="Close"
          >
            <XMarkIcon aria-hidden={true} />
          </Popover.Close>

          <nav className="mt-12 grid w-full divide-y divide-white divide-opacity-10 px-8 pt-4 pb-6">
            {pages.map((page) => (
              <MenuLink page={page} key={page.name} />
            ))}
          </nav>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const MenuLink = ({page}: {page: Page}) => {
  if (page.subpages.length > 0) {
    return (
      <span className="w-md flex flex-col justify-center px-4 py-4 text-lg font-bold text-gray-300">
        {page.name}
        <span className="grid space-y-2 py-4">
          {page.subpages.map((subpage) => (
            <Link key={subpage.name} href={subpage.href} legacyBehavior>
              <a className="w-md ml-3 text-lg font-bold text-gray-300 hover:text-white">
                <Popover.Trigger>{subpage.name}</Popover.Trigger>
              </a>
            </Link>
          ))}
        </span>
      </span>
    );
  }

  return (
    <Link href={page.href} legacyBehavior>
      <a
        className="flex items-center px-4 py-4 text-lg
        font-bold text-gray-300 hover:text-white"
      >
        <Popover.Trigger>{page.name}</Popover.Trigger>
      </a>
    </Link>
  );
};
