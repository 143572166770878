import {useRef, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';

import {EpisodeMetadata, ProducerPass} from '@/utils/types';
import {WHITE_RABBIT_CURRENT_EPISODE_ID} from '@/components/show-page/constants';

export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

// We need to be careful how we use this since all our pages are rendered server-side by default
// See the use of import dynamic from 'next/dynamic'; on how we handle this conditionally.
export const useBreakpoints = () => {
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.sm});

  return {isMobile};
};

export const useProducerPassMetadata = () => {
  return useState<Partial<ProducerPass>>({
    maxPerWallet: 100,
    maxSupply: 1000,
    price: '100000000000000000',
    merkleRoot: '',
  });
};

export const useScroll = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollTo = () => ref?.current?.scrollIntoView({behavior: 'smooth'});

  return {
    ref,
    scrollTo,
  };
};

/**
 * A custom React hook for animating scroll-to behavior to a referenced HTML element
 * with a specified duration using an easing function.
 */
export const useScrollWithDuration = () => {
  // Create a reference to a `div` element to scroll to
  const ref = useRef<HTMLDivElement>(null);

  // Helper function for easing out cubic scroll animation
  const easeOutCubic = (t: number, b: number, c: number, d: number) => {
    const x = t / d;
    return c * (1 - Math.pow(1 - x, 3)) + b;
  };

  // Define a function to scroll to the referenced element with a given duration
  const scrollTo = (duration: number) => {
    // Get the referenced `div` element, if it exists
    const element = ref.current;
    if (!element) return;

    // Calculate the distance to scroll
    const elementYPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    const startingYPosition = window.pageYOffset;
    const distance = elementYPosition - startingYPosition;

    // Record the start time of the animation
    const startTime = performance.now();

    // Define a recursive function to perform the animation
    const animateScroll = (currentTime: number) => {
      // Calculate the elapsed time since the animation began
      const elapsed = currentTime - startTime;

      // Calculate the current scroll position using the easing function
      const scrollProgress = easeOutCubic(
        elapsed,
        startingYPosition,
        distance,
        duration
      );

      // Scroll the window to the current position
      window.scrollTo(0, scrollProgress);

      // If the animation is not yet complete, request another animation frame
      if (elapsed < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    // Start the animation by requesting the first animation frame
    window.requestAnimationFrame(animateScroll);
  };

  // Return the `ref` and `scrollTo` functions as an object
  return {ref, scrollTo};
};

export const checkIsValidEpisodeId = (episodeId: number) => {
  return (
    episodeId > 0 &&
    episodeId <= WHITE_RABBIT_CURRENT_EPISODE_ID &&
    !isNaN(episodeId)
  );
};

export const useEpisode = (id: string | number | null) => {
  const [loading, setLoadingState] = useState<boolean>(true);
  const [error, setErrorMessage] = useState<string | null>(null);
  const [episode, setEpisodeData] = useState<EpisodeMetadata | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoadingState(true);
    setErrorMessage(null);

    fetch(`/api/episodes/${id}`)
      .then((res) => res.json())
      .then(({data}) => {
        console.debug('[debug] Episode metadata:', data);
        setEpisodeData(data);
      })
      .catch((err) => {
        console.error(err);
        const message =
          err.message || String(err) || `Failed to fetch episode ${id}`;
        setErrorMessage(message);
      })
      .finally(() => setLoadingState(false));
  }, [id]);

  return {loading, episode, error};
};

export const useInterval = (
  callback: () => void,
  delay: number,
  skip: boolean
) => {
  const savedCallback = useRef<() => void>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (skip) {
      return;
    }

    const tick = () => {
      savedCallback.current();
    };

    const intervalId = setInterval(tick, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [delay, skip]);
};
